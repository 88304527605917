import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { AxiosAuthInstance } from "../../../AxiosInterceptors";
import dayjs from "dayjs";

const DataRow = ({ weekNumber, namePrefix, location, date, entries, weekdayIdx }) => {
  const data = entries[location]?.[date] || {};

  const startHourKey = `${namePrefix}${weekNumber}startHour`;
  const startMinuteKey = `${namePrefix}${weekNumber}startMinute`;
  const endHourKey = `${namePrefix}${weekNumber}endHour`;
  const endMinuteKey = `${namePrefix}${weekNumber}endMinute`;
  const startHour = data[startHourKey] || "00";
  const startMinute = data[startMinuteKey] || "00";
  const endHour = data[endHourKey] || "00";
  const endMinute = data[endMinuteKey] || "00";

  return (
    <>
      <h3 className="text-[#ABABAB] inter-bold text-[15px]">
        Week {weekNumber}
      </h3>
      <div className='flex'>
        <p className='flex justify-between w-[55px]'>{startHour}  : {startMinute}</p> <p class="mx-2">-</p> <p className='flex justify-end w-[55px]'>{endHour} : {endMinute} </p>
      </div>
    </>
  );
};

function BlockCalender() {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [entries, setEntries] = useState({});
  const [blockEffective, setBlockEffective] = useState({});
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const weekdayCounts = { Monday: 0, Tuesday: 0, Wednesday: 0, Thursday: 0, Friday: 0 };
  const weekdayWeeks = { Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [] };
  const weekdayDates = { Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [] };

  for (let day = 1; day <= daysInMonth; day++) {
    const date = dayjs(new Date(currentYear, currentMonth, day));
    const weekday = date.format('dddd');
    if (weekday in weekdayDates) {
      const dateStr = date.format('YYYY-MM-DD');
      weekdayDates[weekday].push(dateStr);
    }

    if (weekday in weekdayCounts) {
      weekdayCounts[weekday]++;
      const weekNumber = Math.ceil(day / 7);
      weekdayWeeks[weekday].push(weekNumber);
    }
  }

  const fetchMonthData = async (year, month) => {
    setLoading(true);
    try {
      const response = await AxiosAuthInstance.get(`/block-calendar?year=${year}&month=${month + 1}`);
      if (Array.isArray(response.data) && response.data.length > 0) {
        const locationsData = response.data[0]?.locations || {};
        const formattedEntries = {
          MOR: locationsData.MOR || {},
          HOSC: locationsData.HOSC || {},
          ROBOT: locationsData.ROBOT || {},
        };
        setEntries(formattedEntries);
      } else {
        console.error('Unexpected response format:', response.data);
        setErrors({ message: 'No data found for the selected month.' });
      }
    } catch (error) {
      console.error('API Error:', error);
      setErrors({ message: 'Failed to load data for the selected month.' });
    } finally {
      setLoading(false);
    }
  };

  const fetchBlockEffectiveData = async () => {
    setLoading(true);
    try {
      const response = await AxiosAuthInstance.get(`/block-calender-effective-date`);
      setBlockEffective(response.data);
    } catch (error) {
      console.error("API Error:", error);
      setErrors({ message: "Failed to load data for the selected month." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMonthData(currentYear, currentMonth);
    fetchBlockEffectiveData();
  }, [currentDate]);

  const handlePrint = () => {
    const options = { year: 'numeric', month: 'long' };
    const content = document.getElementById("block-calender").outerHTML;
    const printWindow = document.createElement('iframe');
    printWindow.style.position = 'absolute';
    printWindow.style.width = '0px';
    printWindow.style.height = '0px';
    printWindow.style.border = 'none';
    document.body.appendChild(printWindow);
    const printDocument = printWindow.contentWindow.document;
    printDocument.open();
    printDocument.write(`
        <html>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="print-color-adjust" content="exact">
            <head>
                <title>Daily Schedule Calendar</title>
                <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
                <link href="/assets/css/style.css" rel="stylesheet">
                <style>
                  @media print {
                    @page {
                      size: A4 landscape;
                      margin: none; 
                    }
                    body {
                      font-family: Arial, sans-serif;
                    }
                    .page-header, .page-footer {
                      text-align: center;
                      font-size: 12px;
                      color: #333;
                    }
                    .page-header {
                    margin-bottom: 10px;
                      border-bottom: 1px solid #ddd;
                    }
                    .page-footer {
                      margin-top:20px;
                      border-top: 1px solid #ddd;
                    }
                    .print-container {
                      margin: 20px;
                    }
                    .hospital {
                      font-size: 40px;  
                    }
                    .fixture {
                      font-size: 20px;  
                    }
                    .scale {
                      padding-bottom: 20px;
                    }
                    .tables {
                      margin-top: 20px;
                      font-size: 10px !important;
                    }
                    .bolder{
                      font-size: 24px;
                      font-weight: 400;
                    }
                    .blocks td {
                        width: 210px !important;
                        padding: 10px 5px !important;
                        height: auto !important;
                    }
                    th.borders {
                      height: auto !important;
                      width: 100px !important;
                    }
                  }
                </style>
            </head>
            <body class="bg-white">
              ${content}
            </body>
        </html>
    `);
    printDocument.close();

    // Wait for the content to be fully loaded
    printWindow.contentWindow.focus();
    printWindow.contentWindow.print();

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(printWindow);
    }, 1000);
  };

  return (
    <div className="bg-[#748BA2] content-center">
      <div className="global-centering relative w-[1400px] bg-white flex flex-wrap mx-auto rounded-xl shadow-lg">
        <div className="send-msg absolute bg-[#B4C6D9] left-0 right-0 m-auto w-[1165px] top-[-35px] py-5 text-center rounded-xl flex justify-between items-center  px-10">
          <div className="left flex items-center content-center justify-item-center">
            <Link
              to="/dashboard"
              className="svgx flex justify-item-start h-[35px] w-[35px] justify-center items-center rounded bg-white text-[#657E98] hover:bg-[#657E98] hover:transition hover:duration-300"
            >
              <svg
                id="Group_63"
                data-name="Group 63"
                xmlns="http://www.w3.org/2000/svg"
                width="26.19"
                height="23.833"
                viewBox="0 0 26.19 23.833"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_59"
                      data-name="Rectangle 59"
                      width="26.19"
                      height="23.833"
                      transform="translate(0 0)"
                      fill="#657e98"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Group_61"
                  data-name="Group 61"
                  transform="translate(0 0)"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M25.926,12.63,21,7.657V1.577a.327.327,0,0,0-.327-.327H18.309a.327.327,0,0,0-.327.327V4.608L13.59.177a.613.613,0,0,0-.869,0L.267,12.63A.912.912,0,0,0,1.2,14.141h0l2.6-.885v9.822a.75.75,0,0,0,.75.75h4.9a.75.75,0,0,0,.75-.75V15.621h5.927v7.457a.75.75,0,0,0,.75.75h4.9a.75.75,0,0,0,.75-.75V13.292l2.43.829a.912.912,0,0,0,.981-1.491"
                    transform="translate(0 0.005)"
                    fill="#657e98"
                  />
                </g>
              </svg>
            </Link>
            <Link to="/montly-schedule">
              <button
                type="button"
                className="border-none w-[190px] h-[35px] rounded bg-white mx-2 inter-medium text-[18px]"
              >
                Call Calendar View
              </button>
            </Link>
          </div>
          <h1 className="flex inter-medium text-[24px] items-center content-center justify-item-center ps-32">
            Block Time Calendar
          </h1>
          <div className="right flex items-center content-center justify-item-center">
            <button
              onClick={handlePrint}
              type="button"
              className="border-none w-[165px] h-[35px] rounded bg-white mx-2 inter-medium text-[18px]"
            >
              Print Calender
            </button>
            <Link
              to="/edit-block-calender"
              className="border-none w-[165px] h-[35px] flex justify-center items-center rounded bg-white mx-2 inter-medium text-[18px]"
            >
              Edit Calender
            </Link>
          </div>
        </div>

        <div className="w-[1260px] mx-auto pt-[3%] pb-[5%]">
          <div className="relative text-center pt-10 pb-5">
            <h1 className="text-[24px] inter-bold">
              {dayjs().format('MMMM')} <span className="mx-2">{currentYear}</span>
            </h1>
            <p className="text-[18px]">
              {blockEffective[0]?.formatted_date}
            </p>
          </div>
          <div className="week-calender">
            <table className="blocks" id='block-calender'>
              <thead>
                <tr>
                  <th>Location</th>
                  {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((weekday, idx) => (
                    <th key={idx}>{weekday}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {["MOR", "HOSC", "ROBOT"].map((location, idx) => (
                  <tr key={idx}>
                    <th className="borders inter-bold text-[15px]">{location}</th>
                    {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((weekday, weekdayIdx) => (
                      <td key={weekdayIdx}>
                        {weekdayDates[weekday].map((date, dateIdx) => {
                          const weekNumber = weekdayWeeks[weekday][dateIdx] || 1;
                          return (
                            <DataRow
                              key={`${location}-${date}-${weekdayIdx}-${weekNumber}`}
                              weekNumber={weekNumber}
                              namePrefix={`${weekday}-Wk`}
                              entries={entries}
                              date={date}
                              location={location}
                            />
                          );
                        })}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockCalender;
