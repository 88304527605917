import React, { useState, useEffect, useRef } from "react";
import Constant from "../../../Constant";
import { AxiosAuthInstance } from "../../../AxiosInterceptors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

function EditSchedulingSidebar() {
  const [errors, setErrors] = useState({});
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(true);

  const now = new Date();
  const hour = now.getHours();
  const minit = now.getMinutes();
  const ampms = hour >= 12 ? "PM" : "AM";

  const elementRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const toggleVisibility = (index) => {
    if (elementRefs[index].current) {
      elementRefs[index].current.classList.toggle("hidden");
    }
  };

  const handleClickOutside = (event) => {
    elementRefs.forEach((ref) => {
      if (ref.current && !ref.current.contains(event.target)) {
        ref.current.classList.add("hidden");
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [selectedValues, setSelectedValues] = useState({
    eft_am_start_hour: "",
    eft_am_end_hour: "",
    eft_am_start_minit: "",
    eft_am_end_minit: "",
    eft_am_start_ampm: "",
    eft_am_end_ampm: "",
    eft_pm_start_hour: "",
    eft_pm_end_hour: "",
    eft_pm_start_minit: "",
    eft_pm_end_minit: "",
    eft_pm_start_ampm: "",
    eft_pm_end_ampm: "",
  });
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  // Code for Office Closed 
  const [formOfficeCloseData, setFormOfficeCloseData] = useState({
    note: "Close",
    effective_date_start: "",
    create_by: false,
  });

  const handleDateChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const [officeCloseStartDay, setOfficeCloseStartDay] = useState("");
  const [officeCloseStartMonth, setOfficeCloseStartMonth] = useState("");
  const [officeCloseStartYear, setOfficeCloseStartYear] = useState("");
  const [success, setSuccess] = useState(false);

  const handleOfficeCloseSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const effective_date_start = `${officeCloseStartYear}-${officeCloseStartMonth}-${officeCloseStartDay}`;
    try {
      const response = await AxiosAuthInstance.post(
        `${Constant.BASE_URL}/add-ofice-close-data`,
        {
          ...formOfficeCloseData,
          effective_date_start: effective_date_start,
          note: "Close",
        }
      );

      setFormOfficeCloseData({
        note: "Close",
        officeCloseStartDay: "",
        officeCloseStartMonth: "",
        officeCloseStartYear: "",
        create_by: false,
      });

      setErrors({});
      console.log("formOfficeCloseData:", response);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
        console.error("Error response data:", error.response.data.errors);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
      console.error("Error adding office close data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearFormOfficeClose = () => {
    setOfficeCloseStartDay("");
    setOfficeCloseStartMonth("");
    setOfficeCloseStartYear("");
    setErrors({});
    console.log("Form canceled and errors cleared");
  };

  // Office adjust 
  const [formOfficeAdjustData, setFormOfficeAdjusteData] = useState({
    note: "Close",
    effective_date_start: "",
    create_by: false,
  });

  const handleDateAdjustChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const [officeAdjustStartDay, setOfficeAdjustStartDay] = useState("");
  const [officeAdjustStartMonth, setOfficeAdjustStartMonth] = useState("");
  const [officeAdjustStartYear, setOfficeAdjustStartYear] = useState("");

  const [officeAdjustEndtDay, setOfficeAdjustEndDay] = useState("");
  const [officeAdjustEndMonth, setOfficeAdjustEndMonth] = useState("");
  const [officeAdjustEndYear, setOfficeAdjustEndYear] = useState("");

  const handleOfficeAdjustSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const effective_date_start = `${officeAdjustStartYear}-${officeAdjustStartMonth}-${officeAdjustStartDay}`;
    const effective_date_end = `${officeAdjustEndYear}-${officeAdjustEndMonth}-${officeAdjustEndtDay}`;
    try {
      const response = await AxiosAuthInstance.post(
        `${Constant.BASE_URL}/add-ofice-close-data`,
        {
          ...formOfficeCloseData,
          effective_date_start: effective_date_start,
          effective_date_end: effective_date_end,
          note: "Adjust",
        }
      );

      setFormOfficeAdjusteData({
        note: "Adjust",
        officeAdjustStartDay: "",
        officeAdjustStartMonth: "",
        officeAdjustStartYear: "",
        officeAdjustEndtDay: "",
        officeAdjustEndMonth: "",
        officeAdjustEndYear: "",
        create_by: false,
      });

      setErrors({});
      console.log("formOfficeCloseData:", response);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
        console.error("Error response data:", error.response.data.errors);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
      console.error("Error adding office close data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdjustFormOffice = () => {
    setOfficeCloseStartDay("");
    setOfficeCloseStartMonth("");
    setOfficeCloseStartYear("");
    setErrors({});
    console.log("Form canceled and errors cleared");
  };
  const [isHidden1, setIsHidden1] = useState(false);
  const [isHidden2, setIsHidden2] = useState(false);
  const [isHidden3, setIsHidden3] = useState(false);
  const handleClick1 = () => {
    setIsHidden1(true);
  };
  const handleClick2 = () => {
    setIsHidden2(true);
  };
  const handleClick3 = () => {
    setIsHidden3(true);
  };

  return (
    <div className="relative bg-[#F9F9F9] w-[325px] ms-20 rounded-md text-center mt-[45px]">
      <div className="absolute left-[-15px] bg-[#657E98] w-[355px] h-[55px] text-white inter-medium text-[24px] flex rounded-t-md">
        <p className="flex text-center items-center m-auto">Office Closed</p>
      </div>
      <form onSubmit={handleOfficeCloseSave}>
        <div className="flex justify-center items-center rounded-md text-center pt-[85px]" onClick={handleClick1}>
          <div className="flex items-center rounded bg-white">

            <input
              className="py-4 text-center focus:outline-none w-[80px] rounded-l-md"
              type="text"
              placeholder="MM"
              name="officeCloseStartMonth"
              value={officeCloseStartMonth}
              onChange={handleDateChange(setOfficeCloseStartMonth)}
              required
            />
            <span>/</span>
            <input
              className="py-4 text-center focus:outline-none w-[80px]"
              type="text"
              placeholder="DD"
              name="officeCloseStartDay"
              value={officeCloseStartDay}
              onChange={handleDateChange(setOfficeCloseStartDay)}
              required
            />
            <span>/</span>
            <input
              className="py-4 text-center focus:outline-none w-[80px] rounded-r-md"
              type="text"
              placeholder="YYYY"
              name="officeCloseStartYear"
              value={officeCloseStartYear}
              onChange={handleDateChange(setOfficeCloseStartYear)}
              required
            />

          </div>
        </div>
        <div>
          {errors.effective_date_start && <p className="pt-3 text-red-800"><small>{errors.effective_date_start[0]}</small></p>}
          {success && <p className="pt-3 text-green-800"><small>Successfully Closed the Office!</small></p>}
        </div>
        <div className={isHidden1 ? "flex justify-center pt-5" : "flex justify-center pt-5 deactive"}>
          <button
            type="submit"
            className="border-2 border-white h-[35px] w-[35px] hover:bg-[#657E98] hover:text-white rounded-md bg-[#B4C6D9] mx-2"
          >
            <FontAwesomeIcon icon={faCheck} size="xl" />
          </button>
          <button
            className="border-2 border-white h-[35px] w-[35px] hover:bg-[#B87D7D] hover:text-white rounded-md bg-[#D8ADAD] mx-2"
            id="close"
            onClick={handleClearFormOfficeClose}
          >
            <FontAwesomeIcon icon={faXmark} size="xl" />
          </button>
        </div>
      </form>

      <div className="absolute bg-[#657E98] w-[355px] left-[-15px] h-[55px] text-white inter-medium text-[24px] flex mt-[20px] rounded-md">
        <p className="flex text-center items-center m-auto">
          Adjust Default Office Hours
        </p>
      </div>

      <form>
        <div className="m-auto rounded-md text-center pt-[100px]">
          <p className="pb-2">Effective Start Date - AM</p>

          <div className="flex justify-center items-center rounded-md text-center pt-[5px]" onClick={handleClick2}>

            <div className="flex items-center rounded bg-white">
              <input
                className="py-4 text-center focus:outline-none w-[80px] rounded-l-md"
                type="text"
                placeholder="MM"
                name="officeAdjustStartMonth"
                value={officeAdjustStartMonth}
                onChange={handleDateAdjustChange(setOfficeAdjustStartMonth)}
              />
              <span>/</span>
              <input
                className="py-4 text-center focus:outline-none w-[80px]"
                type="text"
                placeholder="DD"
                name="officeAdjustStartDay"
                value={officeAdjustStartDay}
                onChange={handleDateAdjustChange(setOfficeAdjustStartDay)}
              />
              <span>/</span>
              <input
                className="py-4 text-center focus:outline-none w-[80px] rounded-r-md"
                type="text"
                placeholder="YYYY"
                name="officeCloseStartYear"
                value={officeCloseStartYear}
                onChange={handleDateAdjustChange(setOfficeCloseStartYear)}
              />

            </div>
          </div>
          <div className="m-auto pt-5 pb-3 w-[260px] relative">
            <div className="flex items-center justify-center">
              <div
                className="time-stamp1 flex justify-center items-center"
                onClick={() => toggleVisibility(0)}
              >
                <span>
                  {/* {selectedValues.eft_am_start_hour
                    ? selectedValues.eft_am_start_hour
                    : hour} */}
                  <span>7</span>
                  <span className="mx-1">:</span>
                  {/* {selectedValues.eft_am_start_minit
                    ? selectedValues.eft_am_start_minit
                    : minit} */}
                  <span>30</span>
                  <span className="me-2"></span>
                  {/* {selectedValues.eft_am_start_ampm
                    ? selectedValues.eft_am_start_ampm
                    : ampms} */}
                  <span>AM</span>
                </span>
              </div>
              <span className="px-5">TO</span>
              <div className="time-stamp1" onClick={() => toggleVisibility(1)}>
                <span>
                  {/* {selectedValues.eft_am_end_hour
                    ? selectedValues.eft_am_end_hour
                    : hour} */}
                  <span>11</span>
                  <span className="mx-1">:</span>
                  {/* {selectedValues.eft_am_end_minit
                    ? selectedValues.eft_am_end_minit
                    : minit} */}
                  <span>00</span>
                  <span className="ms-2"></span>
                  {/* {selectedValues.eft_am_end_ampm
                    ? selectedValues.eft_am_end_ampm
                    : ampms} */}
                  <span>AM</span>
                </span>
              </div>
            </div>
            <div
              className="absolute bg-white h-[40px] w-[105px] top-3 left-[10px] flex items-center justify-between hidden"
              ref={elementRefs[0]}
            >
              <div className="f-time">
                <select
                  className="w-[40px]"
                  name="eft_am_start_hour"
                  onChange={handleSelectChange}
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                </select>
              </div>
              <span className="ms-[-10px]">:</span>
              <div className="f-time  ms-[0px]">
                <select
                  className="w-[40px]"
                  name="eft_am_start_minit"
                  onChange={handleSelectChange}
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                </select>
              </div>
              <div className="f-time ms-[-15px]">
                <select
                  className="w-[40px]"
                  name="eft_am_start_ampm"
                  onChange={handleSelectChange}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
            <div
              className="absolute bg-white h-[40px] w-[105px] top-3 right-[10px] flex items-center justify-between  hidden"
              ref={elementRefs[1]}
            >
              <div className="f-time">
                <select
                  className="w-[40px]"
                  name="eft_am_end_hour"
                  onChange={handleSelectChange}
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                </select>
              </div>
              <span className="ms-[-10px]">:</span>
              <div className="f-time  ms-[0px]">
                <select
                  className="w-[40px]"
                  name="eft_am_end_minit"
                  onChange={handleSelectChange}
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                </select>
              </div>
              <div className="f-time ms-[-15px]">
                <select
                  className="w-[40px]"
                  name="eft_am_end_ampm"
                  onChange={handleSelectChange}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>
          <div className={isHidden2 ? "flex justify-center py-2" : "flex justify-center py-2 deactive"}>
            <button
              type="submit"
              className="border-2 border-white h-[35px] w-[35px] hover:bg-[#657E98] hover:text-white rounded-md bg-[#B4C6D9] mx-2"
            >
              <FontAwesomeIcon icon={faCheck} size="xl" />
            </button>
            <button
              className="border-2 border-white h-[35px] w-[35px] hover:bg-[#B87D7D] hover:text-white rounded-md bg-[#D8ADAD] mx-2"
              id="close"
            >
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </button>
          </div>

          <hr />

          <div className="m-auto rounded-md text-center pt-[35px]">
            <p className="pb-2">Effective Start Date - PM</p>
            <div className="flex justify-center items-center rounded-md text-center pt-[5px]" onClick={handleClick3}>
              <div className="flex items-center rounded bg-white">
                <input
                  className="py-4 text-center focus:outline-none w-[80px] rounded-l-md"
                  type="text"
                  placeholder="MM"
                  name="officeAdjustEndMonth"
                  value={officeAdjustEndMonth}
                  onChange={handleDateAdjustChange(setOfficeAdjustEndMonth)}
                />
                <span>/</span>
                <input
                  className="py-4 text-center focus:outline-none w-[80px]"
                  type="text"
                  placeholder="DD"
                  name="officeAdjustEndtDay"
                  value={officeAdjustEndtDay}
                  onChange={handleDateAdjustChange(setOfficeAdjustEndDay)}
                />
                <span>/</span>
                <input
                  className="py-4 text-center focus:outline-none w-[80px] rounded-r-md"
                  type="text"
                  placeholder="YYYY"
                  name="officeAdjustEndYear"
                  value={officeAdjustEndYear}
                  onChange={handleDateAdjustChange(setOfficeAdjustEndYear)}
                />
              </div>
            </div>
            <div className="m-auto pt-6 pb-3 w-[260px] relative">
              <div className="flex items-center justify-center">
                <div className="time-stamp1" onClick={() => toggleVisibility(2)}>
                  <span>
                    {/* {selectedValues.eft_pm_start_hour
                      ? selectedValues.eft_pm_start_hour
                      : hour} */}
                    <span>12</span>
                    <span className="mx-1">:</span>
                    {/* {selectedValues.eft_pm_start_minit
                      ? selectedValues.eft_pm_start_minit
                      : minit} */}
                    <span>00</span>
                    <span className="me-2"></span>
                    {/* {selectedValues.eft_pm_start_ampm
                      ? selectedValues.eft_pm_start_ampm
                      : ampms} */}
                    <span>PM</span>
                  </span>
                </div>
                <span className="px-5">TO</span>
                <div className="time-stamp1" onClick={() => toggleVisibility(3)}>
                  <span>
                    {/* {selectedValues.eft_pm_end_hour
                      ? selectedValues.eft_pm_end_hour
                      : hour} */}
                    <span>6</span>
                    <span className="mx-1">:</span>
                    {/* {selectedValues.eft_pm_end_minit
                      ? selectedValues.eft_pm_end_minit
                      : minit} */}
                    <span>00</span>
                    <span className="me-2"></span>
                    {/* {selectedValues.eft_pm_end_ampm
                      ? selectedValues.eft_pm_end_ampm
                      : ampms} */}
                    <span>PM</span>
                  </span>
                </div>
              </div>
              <div
                className="absolute bg-white h-[40px] w-[105px] top-4 left-[10px] flex items-center justify-between hidden"
                ref={elementRefs[2]}
              >
                <div className="f-time">
                  <select
                    className="w-[40px]"
                    name="eft_pm_start_hour"
                    onChange={handleSelectChange}
                  >
                    <option value="01">01</option>
                    <option value="02">02</option>
                  </select>
                </div>
                <span className="ms-[-10px]">:</span>
                <div className="f-time  ms-[0px]">
                  <select
                    className="w-[40px]"
                    name="eft_pm_start_minit"
                    onChange={handleSelectChange}
                  >
                    <option value="01">01</option>
                    <option value="02">02</option>
                  </select>
                </div>
                <div className="f-time ms-[-15px]">
                  <select
                    className="w-[40px]"
                    name="eft_pm_start_ampm"
                    onChange={handleSelectChange}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
              <div
                className="absolute bg-white h-[40px] w-[105px] top-4 right-[10px] flex items-center justify-between hidden"
                ref={elementRefs[3]}
              >
                <div className="f-time">
                  <select
                    className="w-[40px]"
                    name="eft_pm_end_hour"
                    onChange={handleSelectChange}
                  >
                    <option value="01">01</option>
                    <option value="02">02</option>
                  </select>
                </div>
                <span className="ms-[-10px]">:</span>
                <div className="f-time  ms-[0px]">
                  <select
                    className="w-[40px]"
                    name="eft_pm_end_minit"
                    onChange={handleSelectChange}
                  >
                    <option value="01">01</option>
                    <option value="02">02</option>
                  </select>
                </div>
                <div className="f-time ms-[-15px]">
                  <select
                    className="w-[40px]"
                    name="eft_pm_end_ampm"
                    onChange={handleSelectChange}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={isHidden3 ? "flex justify-center py-2" : "flex justify-center py-2 deactive"}>
              <button
                type="submit"
                className="border-2 border-white h-[35px] w-[35px] hover:bg-[#657E98] hover:text-white rounded-md bg-[#B4C6D9] mx-2"
              >
                <FontAwesomeIcon icon={faCheck} size="xl" />
              </button>
              <button
                className="border-2 border-white h-[35px] w-[35px] hover:bg-[#B87D7D] hover:text-white rounded-md bg-[#D8ADAD] mx-2"
                id="close"
                onClick={handleAdjustFormOffice}
              >
                <FontAwesomeIcon icon={faXmark} size="xl" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditSchedulingSidebar;
